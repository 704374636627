import React, {Component} from 'react';
import {
  Segment,
  Header,
  Grid,
  Container,
  Divider,
  Card,
  Image
} from 'semantic-ui-react';
import "animate.css/animate.min.css";
import az from '../images/az.jpg';
import lw from '../images/lw.jpg';
import sw from '../images/sw.jpg';
import kp from '../images/kp.jpg';
import mk from '../images/mk.jpg';
import tm from '../images/tm.jpg';
import gs from '../images/gs.jpg';
import Staff from "./staff";

export default class Team extends Component {
  constructor() {
    super();
    this.persons = [
      {
        "id" : "1",
        "name": "Andrzej Żurawski",
        "description":  "Entrepreneur, computer scientist, doctoral student and lecturer at the Institute of Computer Science and Digital Economy of the Warsaw School of Economics, managing partner at Innovation Spinaker .He participates in the project primarily as a technical advisor and deputy project coordinator.",
        "photo": "az"
      },
      {
        "id" : "2",
        "name": "Lech Wilczyński",
        "description": "Expert in the field of Blockchain technology, speaker, winner of the Creative in Business award, graduate (with honors) of the Polish-Japanese Academy of Information Technology, president of the board of Inpay S.A. He is the President of the board and at the same time Blockchain consultant and a mine of ideas.",
        "photo": "lw"
      },
      {
        "id" : "3",
        "name": "Sławomir Więch",
        "description": "Senior programmer and system architect, experienced IT project manager, a graduate of the Polish-Japanese Academy of Information Technology, has been working in the fin-tech industry for several years. He participates in the project primarily as the head of the software development department. Member of the Supervisory Board.",
        "photo": "sw"
      },
    ]
  }


  render() {
    return (
        <Segment basic>
        {/*
        {
          this.persons.map((x) => {
            let path = "./images/" + x.photo + ".jpg";
            console.log(path);
            if ( (x.id % 4) === 0 ) {
              return (
                <Staff name={x.name} import={path} description={x.description}/>
              )
            }
          })
        }
        */}
          <Container>
            <Header as='h1'>The Team</Header>
            <Divider hidden/>
            <Grid stackable>
              <Grid.Row columns={4} stretched>
                <Grid.Column width={4}>
                  <Card>
                    <Image src={az} wrapped ui={false}/>
                    <Card.Content>
                      <Card.Header>Andrzej Żurawski</Card.Header>
                      <Card.Description>
                        Entrepreneur, computer scientist, doctoral student and lecturer at the Institute of Computer
                        Science and Digital Economy of the Warsaw School of Economics, managing partner at Innovation
                        Spinaker.
                        He participates in the project primarily as a technical advisor and deputy project coordinator.
                      </Card.Description>
                    </Card.Content>
                  </Card>

                </Grid.Column>

                <Grid.Column width={4}>
                  <Card>
                    <Image src={lw} wrapped ui={false}/>
                    <Card.Content>
                      <Card.Header>Lech Wilczyński</Card.Header>
                      <Card.Description>
                        Expert in the field of Blockchain technology, speaker, winner of the Creative in Business award,
                        graduate (with honors) of the Polish-Japanese Academy of Information Technology, president of
                        the board of Inpay S.A.
                        He is the President of the board and at the same time Blockchain consultant and a mine of ideas.
                      </Card.Description>
                    </Card.Content>
                  </Card>
                </Grid.Column>

                <Grid.Column width={4}>
                  <Card>
                    <Image src={sw} wrapped ui={false}/>
                    <Card.Content>
                      <Card.Header>Sławomir Więch</Card.Header>
                      <Card.Description>
                        Senior programmer and system architect, experienced IT project manager, a graduate of the
                        Polish-Japanese Academy of Information Technology, has been working in the fin-tech industry for
                        several years.
                        He participates in the project primarily as the head of the software development department.
                        Member of the Supervisory Board.
                      </Card.Description>
                    </Card.Content>
                  </Card>
                </Grid.Column>

                <Grid.Column width={4}>
                  <Card>
                    <Image src={kp} wrapped ui={false}/>
                    <Card.Content>
                      <Card.Header>Kamil Puszczyński</Card.Header>
                      <Card.Description>
                        Operational Director and project manager. He studied at the Warsaw University of Technology and
                        the Warsaw School of Economics. Ambitious manager and entrepreneur, strongly IT-oriented.
                        In the project, in addition to the management role, he is responsible for technical aspects
                        related to cryptocurrency mining.
                      </Card.Description>
                    </Card.Content>
                  </Card>
                </Grid.Column>

              </Grid.Row>

              <Divider hidden/>

              <Grid.Row columns={3} centered>
                <Grid.Column width={4}>
                  <Card>
                    <Image src={mk} wrapped ui={false}/>
                    <Card.Content>
                      <Card.Header>Mateusz Kowalczyk</Card.Header>
                      <Card.Description>
                        A graduate of electronics and computer engineering at the Warsaw University of Technology, a
                        specialist in the design and implementation of IT systems and Blockchain technology.
                        He participates in the project as a Blockchain consultant and idea initiator.
                        Member of the Supervisory Board.
                      </Card.Description>
                    </Card.Content>
                  </Card>

                </Grid.Column>

                <Grid.Column width={4}>
                  <Card>
                    <Image src={tm} wrapped ui={false}/>
                    <Card.Content>
                      <Card.Header>Tomasz Marczuk</Card.Header>
                      <Card.Description>
                        President of Innovation Spinaker, professionally in recent years associated with the market of
                        consultancy and services in the field of loyalty, payment instruments, marketing and sales,
                        finance, investment.
                        He participates in the project as an advisor in the field of finance and marketing.
                      </Card.Description>
                    </Card.Content>
                  </Card>
                </Grid.Column>

                <Grid.Column width={4}>
                  <Card>
                    <Image src={gs} wrapped ui={false}/>
                    <Card.Content>
                      <Card.Header>Grzegorz Sułkowski</Card.Header>

                      <Card.Description>
                        A graduate of the University of Warsaw, an experienced IT system administrator with many years
                        of practice, a specialist in data cloud-based solutions.
                        He participates in the project as a network architect, administrator and DevOps specialist.
                      </Card.Description>
                    </Card.Content>
                  </Card>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Container>
        </Segment>

    );
  }
}
