/**
 * Created by glazewska on 24/11/2017.
 */
import GlobeProvider from 'react-globe';
import {connect} from 'react-redux';
import locale2 from 'locale2';
import plLocale from 'react-intl/locale-data/pl'
//import enLocale from 'react-intl/locale-data/en'

const translations = {
  pl: {
    localeData: plLocale,
    messages: {
      'About': 'O nas',
      'Company details': 'Dane firmy',
      'Study': 'Badania',
      'EU Project': 'Projekt UE',
      'Investor relations': "Relacje inwestorskie"
    }
  }
};

const mapStateToProps = (state) => ({
  lang: (state.changeLanguage === null) ? locale2.substr(0, 2) : state.changeLanguage,
  translations: translations,
  debug: false
});

export default connect(mapStateToProps)(GlobeProvider)