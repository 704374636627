import React, {Component} from 'react'
import {t} from 'react-globe';
import {Segment, Container, Header} from 'semantic-ui-react';


export default class NotFound extends Component {
    render() {
        return (
            <Container>
                <Segment basic>
                    <Header as='h4'> {t('Page not found')} </Header>
                </Segment>
            </Container>

        )
    }
}
