import React, {Component} from 'react'
import {t} from 'react-globe'

export default class TimerBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      seconds: "..."
    };
  }

  visibility() {
    return this.props.visibility === 'hidden' ? 'hidden' : '';
  }

  render() {
    return (
      <div style={{backgroundColor: '#d7e0f0'}} className={"ui top left attached label primary " + this.visibility()}>
        <i className="time icon"/>
        {t('Expires in')}: {this.state.seconds} s
      </div>
    );
  }

  componentDidMount() {
    this.loadInterval = setInterval(
      () => this.clock(), 1000
    );
  }

  componentWillUnmount () {
    if(this.loadInterval)
      clearInterval(this.loadInterval);
    this.loadInterval = false;
  }

  clock() {
    let seconds = this.state.seconds;
    if(isNaN(seconds) || this.props.seconds < seconds) {
      seconds = (this.props.seconds > 0) ? this.props.seconds : "...";
    } else if(seconds > 0) {
      seconds--;
    }
    this.setState({seconds: seconds});
  }
}

