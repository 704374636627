/**
 * Created by glazewska on 24/11/2017.
 */
import React, {Component} from 'react';
import {Sidebar, Segment, Icon} from 'semantic-ui-react';
import {Route, Switch} from 'react-router-dom';
//import asyncComponent from "./components/AsyncComponent";
//import PanelSidebar from "./components/panel/menu"
//import Footer from './components/footer';
//import Contact from './components/contact';
//import About from './components/about';

import NotFound from './components/notfound';

//import SellTransfer from './components/sell/selltransfer';
//import SellAtm from './components/sell/sellatm';
//import SellSepa from './components/sell/sellsepa';
//import SellDetails from './components/sell/selldetails';
//const AsyncBuyForm = asyncComponent(() => import("./components/buy/buy"));

class Panel extends Component {
  state = {sideBarVisible: true};

  toggleSidebarVisibility = () => this.setState({sideBarVisible: !this.state.sideBarVisible});

  toggleSidebarVisibilityPanel() {
    let screenWidth = window.innerWidth;
    if (screenWidth <= 640 && this.state.sideBarVisible === true) {
      this.setState({sideBarVisible: false});
    }
  }

  render() {

    // const {sideBarVisible} = this.state;

    return (
      <div>
        <Sidebar.Pushable as={Segment}>
          {/*<PanelSidebar visible={sideBarVisible}/>*/}
          <Sidebar.Pusher onClick={this.toggleSidebarVisibilityPanel.bind(this)}
          >
            <div className="ui vertical">
              <Icon onClick={this.toggleSidebarVisibility}
                    size="large"
                    name='sidebar'
                    style={{margin: "1em"}}/>
            </div>

            <Segment basic style={{minHeight: 'calc(100vh - 260px)', padding: '0'}}
            >
              <Switch>
                {/*<Route path='/panel' exact component={UserDetails}/>*/}
                
                
                <Route path='/panel/*' component={NotFound}/>
              </Switch>

            </Segment>
            {/*<Footer/>*/}

          </Sidebar.Pusher>
        </Sidebar.Pushable>
      </div>
    );
  }
}

export default Panel;
