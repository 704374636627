import {combineReducers} from 'redux';
import {LOGIN, USER_GET, CHANGE_LANGUAGE} from '../actions/index'

export function user(state = null, action) {
  switch (action.type) {
    case LOGIN:
    case USER_GET:
      if(typeof action.payload.data === 'undefined') {
        return state;
      }
      return action.payload.data;
    default:
      break;
  }
  return state;
}

export function changeLanguage(state = null, action) {
  switch (action.type) {
    case CHANGE_LANGUAGE:
      return action.lang;
    default:
      break;
  }
  return state;
}


const rootReducer = combineReducers({
  user: user,
  changeLanguage: changeLanguage
});

export default rootReducer;