import React, {Component} from 'react';
import {Item, Segment, Table, Icon} from 'semantic-ui-react';
import {t} from 'react-globe';
import TimerBox from './timerbox';

export default class Payment extends Component {
  constructor(props) {
    super(props);
    this.loadInterval = false;

  }


  componentDidMount() {
    /*this.loadInterval = setInterval(
      () => this.props.callback(), 5000
    );*/
  }

  componentWillUnmount() {
    /*if(this.loadInterval) {
      clearInterval(this.loadInterval);
    }
    this.loadInterval = false;*/
  }

  timerVisibility() {

  }


  render() {
    const payment = this.props.payment;

    console.log(payment);

    if (payment === undefined) {
      return (
        <div>
        </div>
      )
    }

    let remaining = payment.expected_amount - payment.received_amount;
    const apiUrl = "https://api.inpay.pl";
    let qrLink = apiUrl + "/qr/invoice/" + payment.invoice_code + "?r=" + remaining;
    let timerVisibility = (payment.secondsToExpire > 0) ? '' : 'hidden';
    return (
      <div>
        <Segment>
          <TimerBox seconds={payment.secondsToExpire} visibility={timerVisibility}/>

          <Item.Group>
            <Item>
              <Item.Image style={{padding: '7px', backgroundColor: 'white'}} size='small' src={qrLink}/>
              <Item.Content>
                <Item.Description>
                  <Table collapsing celled basic className="very">
                    <Table.Body>
                      <Table.Row>
                        <td>{t('To be paid')}</td>
                        <td><strong>{payment.btcAmount}</strong><i className="btc icon"/></td>
                      </Table.Row>
                      <Table.Row>
                        <td>{t('BTC Address')}</td>
                        <td>{payment.input_address}</td>
                      </Table.Row>
                      <Table.Row>
                        <td>{t('Left to pay')}</td>
                        <td>{remaining}<Icon name="btc"/></td>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </Item.Description>
              </Item.Content>
            </Item>
          </Item.Group>
        </Segment>
      </div>
    );
  }

}