import React, {Component} from 'react';
import {
  Card,
  Image
} from 'semantic-ui-react';
import ScrollAnimation from 'react-animate-on-scroll';
import "animate.css/animate.min.css";

export default class Staff extends Component {
  render() {
    return (
      <Card>
        <Image src={this.props.import} wrapped ui={false}/>
        <Card.Content>
          <Card.Header>{this.props.name}</Card.Header>
          <Card.Meta>
            <span className='date'>Joined in 2015</span>
          </Card.Meta>
          <Card.Description>
            {this.props.description}
          </Card.Description>
        </Card.Content>
        <Card.Content extra>
          Extra content
        </Card.Content>
      </Card>
    );
  }
}
