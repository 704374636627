import {t} from "react-globe";
import React, {Component} from 'react';
import {Container, Header, Segment} from 'semantic-ui-react';

export default class Shareholders extends Component {
  render() {
    return (
      <Segment basic>
        <Container>
          <Header as='h1'>{t('Relacje inwestorskie')}</Header>
          <Header as='h2'>O Spółce</Header>
          <p>InStar Energy Lab S.A.<br/>ul. Grzybowska 4/106 00-131 Warszawa</p>
          <p>NIP: 7010638914, REGON: 366020312<br/>KRS: 0000803322 Sąd Rejonowy Warszawa XII Wydział Gospodarczy</p>
          <Header as='h2'>Akcjonariat</Header>
          <p>Kapitał zakładowy: 100.000 zł<br />
          Kapitał opłacony: 100.000 zł</p>
          <Header as='h2'>Dematerializacja akcji</Header>
          <p>
          W skutek wejścia w życie ustawy dnia 30 sierpnia 2019 r. o zmianie ustawy - Kodeks spółek handlowych oraz niektórych innych ustaw
          (Dz.U. z 2019 r. poz. 1798) najpóźniej do 31.12.2020 r. należy złożyć w spółce wszystkie papierowe dokumenty własności akcji aby mogły być
          przekształcone w formę elektroniczną. Moc obowiązująca dokumentów akcji wydanych przez spółkę wygasa z mocy prawa z dniem 1 stycznia 2021 r.
          </p>
          <p>Złożenie dokumentów akcji w spółce odbywa się za pisemnym pokwitowaniem wydanym akcjonariuszowi.</p>
          <Header as='h2'>Rejestr akcjonariuszy</Header>
          <Header as='h2'>Walne Zgromadzenia / ogłoszenia</Header>
          <p>
          Zarząd InStar Energy Lab S.A. z siedzibą w Warszawie (00-131), ul. Grzybowska 4/106., numer KRS 0000803322, zawiadamia, że dnia 23.12.2019 odbyło się Nadzwyczajne Walne Zgromadzenie InStar Energy Lab, na którym podjęto uchwały stanowiące podstawę do następujących wpisów w KRS:
          rezygnacja Pana Lecha Wilczyńskiego z uczestnictwa w Radzie Nadzorczej Instar Energy Lab S.A.
          powołanie na członka Rady Nadzorczej Instar Energy Lab S.A. Pana Krzysztofa Piecha,
          </p>
          <p>
          Zarząd InStar Energy Lab S.A. z siedzibą w Warszawie (00-131), ul. Grzybowska 4/106, numer KRS 0000803322, zawiadamia, że dnia 23.12.2019 odbyło się Posiedzenie Rady Nadzorczej  InStar Energy Lab, na którym podjęto uchwały stanowiące podstawę do następujących wpisów w KRS:
          odwołanie Pana Kamila Puszczyńskiego z funkcji Prezesa Zarządu Instar Energy Lab S.A.
          powołanie na Prezesa Zarządu Instar Energy Lab S.A. Pana Lecha Wilczyńskiego
          </p>
          <p>
          Zarząd InStar Energy Lab S.A. z siedzibą w Warszawie (00-131), ul. Grzybowska 4/106, numer KRS 0000803322, zawiadamia, że dnia 23.12.2019 ustanowił Pana Kamila Puszczyńskiego prokurentem (prokura samoistna) InStar Energy Lab S.A.
          </p>
        </Container>
      </Segment>
    );
  }
}
