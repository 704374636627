import React, {Component} from 'react';
import {Table, Icon} from 'semantic-ui-react'
//import {t} from 'react-globe';

export default class ComparisonTable extends Component {

    render() {

        return (
            <div>
                <Table celled fixed striped>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Self mining</Table.HeaderCell>
                            <Table.HeaderCell>With Us</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        <Table.Row>
                            <Table.Cell>Any single failure of: internet connection, cooling system, power system,
                                configuration malfunction, device malfunction, pool crash, human mistake or error, won't
                                be solved fast and efficient
                            </Table.Cell>
                            <Table.Cell positive><Icon name='checkmark'/>
                                With InStar system any failures and malfunction of that kind are dispersed among all
                                users, and do not affect single users much
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>While mining on your own you will lose time wiating for shipement and
                                deployment.</Table.Cell>
                            <Table.Cell positive><Icon name='checkmark'/>You can start mining instantly.</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>You will carry high retail energy costs, or alternative costs and limits if
                                using electricity illegally</Table.Cell>
                            <Table.Cell positive><Icon name='checkmark'/> You most probably will not be able to achieve
                                such little energy costs.</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell></Table.Cell>
                            <Table.Cell positive><Icon name='checkmark'/>Instant start, you do not need to wait days for
                                mining start.</Table.Cell>
                        </Table.Row>
                    </Table.Body>
                </Table>
            </div>
        )
    }
}