import React, {Component} from 'react';
import {t} from 'react-globe';
import {Table, Container} from 'semantic-ui-react'

export default class ContractDetails extends Component {

    /*constructor(props) {
        super(props);
    }*/


    render() {
        const c = this.props.contractData;

        if (c === undefined) {
            return (
                <div>

                </div>
            )
        }

        return (
            <Container className="centered">
                <Table celled fixed striped>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>{t('Attribute')}</Table.HeaderCell>
                            <Table.HeaderCell>{t('Value')}</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        <Table.Row>
                            <Table.Cell>{t('Contract id')}:</Table.Cell>
                            <Table.Cell>
                                <a href={'/contract/' + c.code}>{c.code}</a>
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>{t('Date started')}:</Table.Cell>
                            <Table.Cell>{c.close_date}</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>{t('Payout address')}:</Table.Cell>
                            <Table.Cell>{c.btc_address}</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>{t('Hashing power')}:</Table.Cell>
                            <Table.Cell>{c.ths}</Table.Cell>
                        </Table.Row>
                    </Table.Body>
                </Table>
            </Container>
        );
    }

}