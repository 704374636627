import {t} from "react-globe";
import React, {Component} from 'react';
import {Container, Divider, Header, List, Segment} from 'semantic-ui-react';

export default class Project extends Component {
  render() {
    return (
      <Segment basic id='fill-screen'>
        <Container>
          <Header as='h1'>{t('Informacja o projekcie')}</Header>
          <p>
          Instar Energy Lab S.A. realizuje projekt dofinansowany z Funduszy Europejskich pn: „Stworzenie
            centrum badawczo rozwojowego w obszarze zastosowań technologii Blockchain w energetyce”.
          </p>
            <Divider hidden />
            <p>
            Celem projektu jest utworzenie nowego centrum badawczo-rozwojowego, w którym będzie tworzony i
            testowany
            innowacyjny produkt składający się z:
            </p>
            <List ordered>
              <List.Item>
                Instalacji układów obliczeniowych dla technologii Blockchain,
              </List.Item>
              <List.Item>
                Baterii akumulatorów spełniających jednocześnie rolę awaryjnego źródła zasilania i elementu
              kompensującego odchylenia w profilu poboru mocy przez instalację (minimalizacja kosztów
              bilansowania),
              </List.Item>
              <List.Item>
                Oprogramowania do zarządzania pracą instalacji w sposób optymalny energetycznie (dla polskiego rynku
              energii) z uwzględnieniem potrzeb mechanizmu interwencyjnego programu DSR.
              Wśród najważniejszych rezultatów projektu wymienić można wzrost nakładów inwestycyjnych oraz na
              działalność badawczo – rozwojową, a także wzrost zatrudnienia o 2,5 EPC.
              </List.Item>
            </List>
            <Divider hidden />
            <p>
            Wartość projektu: 11 268 522,00 PLN<br/>
            Koszty kwalifikowane: 9 161 400,00 PLN<br/>
            Dofinansowanie projektu z UE: 6 367 980,00 PLN<br/>
            </p>
        </Container>
      </Segment>
    );
  }
}
