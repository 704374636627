import React from 'react';
import store from './store';
import {Provider} from 'react-redux';
import WrappedGlobeProvider from './globe';
import {Route, Router, Switch} from 'react-router-dom';
import history from './history';
//import asyncComponent from "./components/AsyncComponent";
import App from './App';
//import ReactGA from 'react-ga';
import Panel from './panel';
//const Panel = asyncComponent(() => import("./panel"));


/*
if (typeof ReactGA.initialize === 'function')
  ReactGA.initialize('UA-42637252-1');
*/

/*
function logPageView() {
  console.log(window.location.pathname + window.location.search);
  if (typeof ReactGA.initialize === 'function') {
    ReactGA.set({page: window.location.pathname + window.location.search});
    ReactGA.pageview(window.location.pathname + window.location.search);
  }
}
*/


const Routes = () => (
  <Router history={history}
    // onUpdate={logPageView}
  >
    <Switch>
      <Route path="/panel" component={Panel}/>
      <Route path="/" component={App}/>
    </Switch>
  </Router>
);

const FullApp = () => (
  <Provider store={store}>
    <WrappedGlobeProvider>
      <Routes/>
    </WrappedGlobeProvider>
  </Provider>
);

export default FullApp