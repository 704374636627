import React, {Component} from 'react';
import {t} from 'react-globe';
import ContractDetails from "./contract_details";
import Terms from "./terms";
import {Divider, Container} from 'semantic-ui-react'
import Payment from "./payment";
import axios from 'axios'
import qs from 'qs'

export default class Contract extends Component {

    constructor(props) {
        super(props);
        this.apiURL = "https://api.inpay.pl";

        let cd = {
            btc_address: "-",
            prod_generation: "-",
            ths: "-",
            close_date: t('Not Paid yet')
        };

        if(this.props.params !== undefined) {
            this.state = {
                code: this.props.params.contractId,
                contractDetails: cd
            };
        } else {
            this.state = {
                code: 'xtqsibdka293g51mrjwlh8yfe',
                contractDetails: cd
            };
        }

        this.getStatus();
    }

    getStatus() {
        let that = this;
        if(this.state.code === undefined) {
            return null;
        }

        axios.post(this.apiURL + '/instar/status',
            qs.stringify({
                code: this.state.code
            })
        ).then(function(results) {
            console.log(results.data);
            let s = results.data;
            that.setState({
                contractDetails: {
                    code: s.code,
                    btc_address: s.btc_address,
                    prod_generation: s.prod_generation,
                    ths: s.ths,
                    close_date: s.payment.close_date
                },
                payment: s.payment
            })
        });

    }

    render() {

        return (
            <Container className="centered">

                <Divider horizontal>{t('Payment details')}</Divider>

                <Payment payment={this.state.payment}/>

                <Divider horizontal>{t('Contract details')}</Divider>

                <ContractDetails contractData={this.state.contractDetails}/>

                <Terms/>

            </Container>
        );
    }

}