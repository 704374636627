import React, {Component} from 'react';
import {
  Segment,
  Header,
  Grid,
  Container,
  Divider,
  List,
  Image, Progress
} from 'semantic-ui-react';
import roadmap from '../images/roadmap.png';
import ScrollAnimation from 'react-animate-on-scroll';
import "animate.css/animate.min.css";
import btc from '../images/btc.png';
import i001 from '../images/001.png';
import i002 from '../images/002.png';
import i003 from '../images/003.png';
import i004 from '../images/004.png';
import market from '../images/market.jpg';
import charts from '../images/charts.jpg';
import charts2 from '../images/charts2.jpg';

export default class Intro extends Component {
  // constructor() {
  //   super();
  // this.state = {
  //   offset: 0
  // }
  // }

  // componentDidMount() {
  //   window.addEventListener('scroll', this.handleScroll.bind(this));
  // }
  //
  // componentWillUnmount() {
  //   window.removeEventListener('scroll', this.handleScroll.bind(this));
  // }
  //inte
  // handleScroll() {
  //   this.setState({
  //     offset: window.pageYOffset
  //   })
  // }

  render() {
    // let x = Math.floor(this.state.offset * 100 / (document.documentElement.scrollHeight - 1056));
    return (
      <Segment basic style={{position: 'relative', marginLeft: 0, marginRight: 0, paddingLeft: 0, paddingRight: 0}}>
        {/*<Progress percent={x} attached='top'/>*/}
        {/*<p>Page offset: {x}</p>*/}
        <Container>
          <Header as='h1'>InStar Energy Lab</Header>
          <p>Energy sector faces huge challenges ahead particularly in Poland. Most of energy production
            in coal
            based and balancing the network is becoming more complex than ever mainly because of
            different sources
            and varying demand on the market. Key developments in energy transmission are more efficient
            ways to
            store excess energy and quickly respond to peak demand of consumers and so preventing
            black-outs.
            Third issue we face are ways to increase cost efficiency of existing power plants.
          </p>
          <Divider hidden/>
          <Header as='h2'>Long term goal</Header>
          <p>These positive and competitive conditions allow us to plan deployment of containerized
            solutions for
            blockchain data processing. Assuming large scale operations and low cost energy sources it
            is highly
            stable profitable business even though in the long term profit margins due to fierce global
            competition
            have asymptotic tendencies.</p>
          <Divider hidden/>
          <Header as='h2'>Container blockchain data centers with heat recovery</Header>
          {/*<Grid stackable stretched>*/}
          {/*  <Grid.Row columns={2}>*/}
          {/*    <Grid.Column width={12}>*/}
          {/*      <Header as='h3'>Problem I</Header>*/}
          {/*      <List bulleted relaxed>*/}
          {/*        <List.Item>*/}
          {/*          In 2018, 5 TWh of electricity from wind farms in Germany could not be produced because*/}
          {/*          the electricity grid was full (turbines turned off)*/}
          {/*        </List.Item>*/}
          {/*        <List.Item>*/}
          {/*          it's about the same as the annual electricity consumption by 2.5 MILLION households*/}
          {/*        </List.Item>*/}
          {/*        <List.Item>*/}
          {/*          and this problem concerns not only Germany, but many other countries with a large number of*/}
          {/*          power plants based on renewable energy sources*/}
          {/*        </List.Item>*/}
          {/*      </List>*/}
          {/*    </Grid.Column>*/}
          {/*  </Grid.Row>*/}
          {/*</Grid>*/}
          <Divider hidden/>



  <Grid stackable>
    <Grid.Row columns={1}>
      <Grid.Column width={8}>


          <Header as='h3'>Problem I</Header>
        <List bulleted relaxed>
          <List.Item>
            In 2018, 5 TWh of electricity from wind farms in Germany could not be produced because
            the electricity grid was full (turbines turned off)
          </List.Item>
          <List.Item>
            it's about the same as the annual electricity consumption by 2.5 MILLION households
          </List.Item>
          <List.Item>
            and this problem concerns not only Germany, but many other countries with a large number of
            power plants based on renewable energy sources
          </List.Item>
        </List>

      </Grid.Column>
    </Grid.Row>
  </Grid>



          <Divider hidden/>
          <Grid stackable>
            <Grid.Row columns={2}>
              <Grid.Column width={12}>
                <Header as='h3'>Problem II</Header>
                <List bulleted relaxed>
                  <List.Item>
                    Although Poland is still investing in Energy SOURCES, the THREAT of blackouts is still very
                    real.
                    This problem is even more serious in less developed countries.
                  </List.Item>
                  <List.Item>
                    PSE, the country's energy network operator, runs the DSR (Demand Side Response) program, in
                    which
                    it pays large consumers for their willingness to disconnect their RECEIVERS whenever PSE
                    requests
                    it (when there is a risk of possible network overload).
                  </List.Item>
                  <List.Item>
                    The DSR program is used to stabilize the energy network, but few companies can afford to
                    suddenly
                    stop all energy-consuming processes (for a few hours) - in 2018, less than 2% of the received
                    power participated in the program.
                  </List.Item>
                </List>
              </Grid.Column>
            </Grid.Row>
            <Divider hidden/>
            <Grid.Row columns={2}>
              <Grid.Column width={12}>
                <Header as='h3'>Problem III</Header>
                <List bulleted relaxed>
                  <List.Item>
                    It is estimated that the Bitcoin (and other cryptocurrency) mining process is responsible for 1%
                    of global energy consumption. Some sources state that almost ¾ of this energy comes from
                    renewable
                    sources
                  </List.Item>
                  <List.Item>
                    the vast majority of the generated energy is emitted as heat In the process of cryptocurrency
                    mining,
                  </List.Item>
                  <List.Item>
                    Although the process of global adaptation of Bitcoin is unlikely to stop, there is a problem of
                    negative perception of the mining process, also in the context of wasting generated heat energy
                  </List.Item>
                </List>
              </Grid.Column>
              <Grid.Column width={4}/>
            </Grid.Row>

            <Divider hidden/>

            <Grid.Row>
              <Grid.Column>
                <ScrollAnimation animateIn='fadeIn' animateOut='fadeOut' duration={2}>
                  <Segment textAlign='center' raised color='violet' inverted padded='very' id='cut-rectangle'>
                    <Header as='h2' textAlign='center'>Solution</Header>
                    <p style={{textTransform: 'uppercase'}}><b><u>Wouldn’t be awesome if you could:</u></b></p>
                    <List relaxed>
                      <List.Item>
                        Allocate the surplus of energy produced by renewable sources in a completely innovative way
                        in bitcoin?
                      </List.Item>
                      <List.Item>
                        Stabilize the energy network at the same time?
                      </List.Item>
                      <List.Item>
                        Re-use most of the heat generated in a process of cryptocurrency mining?
                      </List.Item>
                    </List>
                  </Segment>
                </ScrollAnimation>
              </Grid.Column>
            </Grid.Row>

            <Divider hidden/>

            <Grid.Row>
              <Grid.Column>
                <Header as='h3' textAlign='center'>What if all this could be achieved with one product?</Header>
              </Grid.Column>
            </Grid.Row>

            <Divider hidden/>

            <Grid.Row columns={2} stretched>
              <Grid.Column>
                <Segment padded='very' color='blue' inverted>
                  Fully remotely managed and autonomous, ready to be connected to an energy source – THE
                  TURN-ON-AND-FORGET MODEL
                </Segment>
              </Grid.Column>
              <Grid.Column>
                <Segment padded='very' color='teal' inverted>
                  Proprietary optimization software based on machine learning algorithms
                </Segment>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row columns={2} stretched>
              <Grid.Column>
                <Segment padded='very' color='pink' inverted>
                  Adapted to participate in the DSR program - high power and appropriate software
                </Segment>
              </Grid.Column>
              <Grid.Column>
                <Segment padded='very' color='orange' inverted>
                  Modules for heat recovery - drying wood, grain, malt; heating of rooms, greenhouses, swimming
                  pools
                </Segment>
              </Grid.Column>
            </Grid.Row>

            <Divider hidden/>

            <Grid.Row columns={2}>
              <Grid.Column width={12}>
                <Header as='h3'>Potential Markets I</Header>
                <List relaxed bulleted>
                  <List.Item>
                    Producers of energy from renewable sources - windmills, photovoltaics, hydroelectric plants,
                    geothermal and classic - nuclear, coal and gas power plants
                  </List.Item>
                  <List.Item>
                    2019 is the tenth consecutive year when the value of global investment in the RES exceeds $ 200
                    billion
                  </List.Item>
                </List>
              </Grid.Column>
              <Grid.Column width={4}>
                <Image src={charts2}/>
              </Grid.Column>
            </Grid.Row>

            <Divider hidden/>

            <Grid.Row columns={2}>
              <Grid.Column width={4}>
                <Image src={market}/>
              </Grid.Column>
              <Grid.Column width={12}>
                <Header as='h3'>Potential Markets II</Header>
                <List relaxed bulleted>
                  <List.Item>
                    Large production plants that can achieve synergies (e.g. sawmills, malt houses, agricultural
                    processing plants)
                  </List.Item>
                  <List.Item>
                    Especially large sawmills often have their combined heat and power plants, which can be used to
                    power the container data center
                  </List.Item>
                  <List.Item>
                    Owners of large retail and service areas - a typical large shopping mall has 2 MW boilers
                    installed and an electrical connection for several hundred kilowatts of power
                  </List.Item>
                </List>
              </Grid.Column>
            </Grid.Row>

            <Divider hidden/>

            <Grid.Row columns={2}>
              <Grid.Column width={12}>
                <Header as='h3'>Potential Markets III</Header>
                <List relaxed bulleted>
                  <List.Item>
                    Professional cryptocurrency mines and traditional server rooms (proprietary optimization
                    software)
                  </List.Item>
                  <List.Item>
                    The global data center market is growing by an average of 11% per year.
                  </List.Item>
                  <List.Item>
                    Total annual revenues from mining cryptocurrencies exceed $ 6 billion, while the cost of
                    electricity consumed is slightly above $ 3.5 billion
                  </List.Item>
                </List>
              </Grid.Column>
              <Grid.Column width={4}>
                <Image src={charts}/>
              </Grid.Column>
            </Grid.Row>

            <Divider hidden/>

            <Grid.Row>
              <Grid.Column>
                <Header as='h3' textAlign='center'>Business Model</Header>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={5} stretched>
              <Grid.Column width={3}>
                <ScrollAnimation animateIn='bounceInLeft' animateOut='bounceOutRight' duration={1}>
                  <Segment color='green'>
                    <p><b>I.</b><br/>sales of software for managing and optimizing the work of a computing center </p>
                    <Header.Subheader>(SaaS or box model) !</Header.Subheader>
                  </Segment>
                </ScrollAnimation>
              </Grid.Column>
              <Grid.Column>
                <ScrollAnimation delay={500} animateIn='bounceInLeft' animateOut='bounceOutRight' duration={1}>
                  <Segment color='blue' width={3}>
                    <p><b>II.</b><br/>integration and sale of container DATA CENTERS (partnership with a hardware
                      company)
                    </p>
                    <Header.Subheader>DIRECT sales or rent model</Header.Subheader>
                  </Segment>
                </ScrollAnimation>
              </Grid.Column>
              <Grid.Column>
                <ScrollAnimation delay={1000} animateIn='bounceInLeft' animateOut='bounceOutRight' duration={1}>
                  <Segment color='pink' width={3}>
                    <p><b>III.</b><br/>advisory and consultancy in the field of building and using Blockchain container
                      server rooms</p>
                  </Segment>
                </ScrollAnimation>
              </Grid.Column>
              <Grid.Column>
                <ScrollAnimation delay={1500} animateIn='bounceInLeft' animateOut='bounceOutRight' duration={1}>
                  <Segment color='olive' width={3}>
                    <p><b>IV.</b><br/>until the product launches - earning on cryptocurrencies mined at the company’s
                      R&D
                      center</p>
                  </Segment>
                </ScrollAnimation>
              </Grid.Column>
              <Grid.Column>
                <ScrollAnimation delay={2000} animateIn='bounceInLeft' animateOut='bounceOutRight' duration={1}>
                  <Segment color='yellow' width={3}>
                    <p><b>V.</b><br/>after selling a sufficient number of mining containers, we will be able to become
                      an
                      aggregator of the DSR system and earn on commissions from other entities participating in the
                      system</p>
                  </Segment>
                </ScrollAnimation>
              </Grid.Column>
            </Grid.Row>

            <Divider hidden/>

            <Grid.Row columns={2}>

              <Grid.Column width={6}>
                <Header as='h3'>Competitors</Header>
                <List bulleted relaxed>
                  <List.Item>
                    Pracownia nowych technologii (POLAND)
                  </List.Item>
                  <List.Item>
                    Power Mining (LITHUANIA)
                  </List.Item>
                  <List.Item>
                    Nordcoin Mining (Estonia)
                  </List.Item>
                  <List.Item>
                    CoolNet (CHINA)
                  </List.Item>
                  <List.Item>
                    BitFury (GEORGIA)
                  </List.Item>
                  <List.Item>
                    Protocubes (USA)
                  </List.Item>
                  {/*<List.Item>*/}
                  {/*  <p>*/}
                  {/*    Everyone focuses on classic cooling and hardware solutions, nobody offers comprehensive software for managing and optimizing the work of a computing node*/}
                  {/*  </p>*/}
                  {/*</List.Item>*/}
                </List>
              </Grid.Column>
              <Grid.Column width={10} stretched>
                <Segment color='violet' inverted padded='very' className="animate-me">
                  <b>Everyone</b> focuses on classic cooling and hardware solutions. <b>Nobody</b> offers <b>comprehensive
                  software</b> for managing and optimizing the work of a computing node.
                </Segment>
              </Grid.Column>
            </Grid.Row>

            {/*<h1 className="animated infinite bounce delay-2s">Example</h1>*/}
            {/*<Grid.Row>*/}
            {/*  <p>*/}
            {/*    <Message>Everyone focuses on classic cooling and hardware solutions, nobody offers comprehensive*/}
            {/*      software for managing and optimizing the work of a computing node</Message>*/}
            {/*  </p>*/}
            {/*</Grid.Row>*/}

            <Divider hidden/>

            <Grid.Row columns={2}>
              <Grid.Column width={4}/>
              <Grid.Column width={12}>
                <Header as='h3'>Our Advantage</Header>
                <List bulleted relaxed>
                  <List.Item>
                    We are building a research and development center (CBR), for which we received a grant from the
                    Ministry of Investment and Development (the value of the project exceeds CHF 2.5 million)
                  </List.Item>
                  <List.Item>
                    As part of the project, we buy and install over 1,000 servers for Blockchain transaction
                    authorization and infrastructure for them
                  </List.Item>
                  <List.Item>
                    Tests are carried out on this equipment with the goal of two things: developing a container
                    prototype and optimization software
                  </List.Item>
                  <List.Item>
                    A side effect of servers WORKING is significant profits from mining cryptocurrencies, at a
                    target scale of over CHF 50,000 a month (at today's BTC rate)
                  </List.Item>
                </List>
              </Grid.Column>
            </Grid.Row>

            <Divider hidden/>

            <Grid.Row columns={2}>
              <Grid.Column width={12}>
                <Header as='h3'>Our Advantage II</Header>
                <List bulleted relaxed>
                  <List.Item>
                    We are already operating at a 1 MW solar farm and considering moving to 1.5 MW biogas plant with
                    cheap energy
                  </List.Item>
                  <List.Item>
                    We also consider building a 1MW cogeneration plant to significantly reduce the price of
                    electricity and explore potential synergies
                  </List.Item>
                  <List.Item>
                    We have letters of intent from well-known Polish energy companies interested in implementing our
                    product
                  </List.Item>
                </List>
              </Grid.Column>
              <Grid.Column width={4}/>
            </Grid.Row>

            <Divider hidden/>

            <Grid.Row columns={2}>
              <Grid.Column width={4}/>
              <Grid.Column width={12}>
                <Header as='h3'>Financial Model</Header>
                <List bulleted relaxed>
                  <List.Item>
                    In the first 2 years, the main source of revenue will be cryptocurrency mining taking place in
                    parallel with the rest of the work of the R&D center. Careful calculations indicate possible
                    ANNUAL profits between CHF 0.5 and 0.75 million
                  </List.Item>
                  <List.Item>
                    the analysis is based on OUR experience, own studies (we have been analyzing the cryptocurrency
                    market for a year) and data available on the Internet
                  </List.Item>
                  <List.Item>
                    In the following years, we assume a decreasing profit from cryptocurrency mining (depreciation
                    of owned equipment) and a growing profit from product sales (technology and containers)
                  </List.Item>
                  <List.Item>
                    Profitability is strongly dependent on the BTC rate and electricity prices
                  </List.Item>
                </List>
              </Grid.Column>
            </Grid.Row>

            <Divider hidden/>

            <Grid.Row>
              <ScrollAnimation animateIn='fadeIn' animateOut='fadeOut' duration={2}>
                <Image src={roadmap} alt='roadmap'/>
              </ScrollAnimation>
            </Grid.Row>

            {/*

            <Divider hidden/>

            <Grid.Row columns={2}>
              <Grid.Column width={12}>
                <Header as='h3'>Investment</Header>
                <List bulleted relaxed>
                  <List.Item>
                    We plan to raise CHF 0.5 - 1.8 million of capital
                  </List.Item>
                  <List.Item>
                    Possible entry in three WAYS:
                    <List.List>
                      <List.Item>
                        Purchase of equity (preferred shares with dividend of 150%)
                      </List.Item>
                      <List.Item>
                        Equity purchase and buy back guaranteeD after 2 years for 140% of their purchase price
                      </List.Item>
                      <List.Item>
                        An attractive interest-bearing loan for a company, structured as short-term loan for ⅔ and
                        14-months loan for the remaining ⅓ of the ammount
                      </List.Item>
                    </List.List>
                  </List.Item>
                  <List.Item>
                    the raised capital will be allocated in the purchase of fixed assets for the project (mostly
                    servers), and then to the construction of a container data center prototype
                  </List.Item>
                </List>
              </Grid.Column>
            </Grid.Row>

            <Divider hidden/>

            <Grid.Row columns={2}>
              <Grid.Column width={4}/>
              <Grid.Column width={12}>
                <Header as='h3'>Loan - Spending Schedule</Header>
                <List bulleted relaxed>
                  <List.Item>
                    (day 1) Purchase of fixed assets for the project - ASIC and GPU servers, container
                    infrastructure. Expenditure: CHF 1,85M gross.
                  </List.Item>
                  <List.Item>
                    (max day 30) Submission of a VAT declaration for a tax refund
                  </List.Item>
                  <List.Item>
                    (max day 30) Submission of a refund application to the Ministry of Investment and Development
                  </List.Item>
                  <List.Item>
                    (max day 90) VAT Refund of CHF 0,393M (within 60 days)
                  </List.Item>
                  <List.Item>
                    (max day 90) Return of CHF 1M - a subsidy from the Ministry of Investment and Development
                    (within 60 days)
                  </List.Item>
                  <List.Item>
                    (max day 90) Return of CHF 1M - a subsidy from the Ministry of Investment and Development
                    (within 60 days)
                  </List.Item>
                  <List.Item>
                    (day 91) Return of short-term loan (CHF 1,393M) to the investor.
                  </List.Item>
                  <List.Item>
                    (day 517) Return of the remaining CHF 0.457M after 14 months. Source: profits from the operation
                    of the servers purchased.
                  </List.Item>
                </List>
              </Grid.Column>
            </Grid.Row>
*/}

          </Grid>
        </Container>

      </Segment>
    );
  }
}
