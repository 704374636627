import React, {Component} from 'react';
import {t} from 'react-globe';
import {Segment, Image, Container, Grid, Header, Table, Divider} from 'semantic-ui-react';
import logo_FE from '../images/logo_FE_Inteligentny_Rozwoj_rgb-1.jpg';
import UE_EFRR from '../images/UE_EFRR_rgb-1.jpg';

export default class Footer extends Component {
  render() {
    return (
      <div>
      <Container>
        <Table columns={2} basic="very">
          <Table.Body>
            <Table.Row>
              <Table.Cell width={13}>
                <Image src={logo_FE} size='small'/>
              </Table.Cell>
              <Table.Cell width={3} textAlign='right'>
                <Image src={UE_EFRR} size='small'/>
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </Container>

      <Divider hidden />

      <Segment inverted vertical>
        <Divider hidden />
        <Container>
          <Grid stackable divided stretched>
            <Grid.Column width={6}>
              <Header inverted as='h4'>{t('About')}</Header>
                InStar Energy Lab S.A.<br/>
                ul. Grzybowska 4/106 00-131 Warszawa
            </Grid.Column>
            <Grid.Column width={8}>
              <Header inverted as='h4'>{t('Company details')}</Header>
                NIP: 7010638914, REGON: 366020312<br/>
                KRS: 0000803322 Sąd Rejonowy Warszawa XII Wydział Gospodarczy

            </Grid.Column>
          </Grid>
        </Container>
        <Divider hidden />
      </Segment>
      </div>
    );
  }
}
