import querystring from 'querystring';
//import history from '../history';
import axios from 'axios';
const ROOT_URL = 'https://api.inpay.pl';
export const LOGIN = 'LOGIN';
export const USER_GET = 'LOGIN';
export const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE';


export function loginUser(formData) {
  const url = `${ROOT_URL}/user/login`;
  const request = axios.post(
    url,
    querystring.stringify(formData), {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded"
      },
      "withCredentials": true
    }
  );
  return {
    type: LOGIN,
    payload: request
  };
}

export function fetchUser(formData) {
  const url = `${ROOT_URL}/user/get`;
  const request = axios.post(
    url,
    querystring.stringify(formData),
    {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded"
      },
      "withCredentials": true
    }
  );
  return {
    type: USER_GET,
    payload: request
  };
}

export function changeLanguage(language) {
  return {
    type: CHANGE_LANGUAGE,
    lang: language
  }
}
