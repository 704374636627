import React, {Component} from 'react';
import { Divider } from 'semantic-ui-react'
//import {t} from 'react-globe';

export default class Terms extends Component {

    render() {

        return (
            <div>
                <Divider horizontal>Contract Terms and Conditions</Divider>
                <h3>Contract objective</h3>
                We are offering contracts for mining devices. You're buying mining devices which will be held solely in
                our facilities. They will be maintained and supported by us.

                <h3>Sales</h3>

                Price is fixed at US Dollar. The InPay price is used to determine a real-time bitcoin price.
                Customers may choose to make payment in Bitcoin, or fiat currency when the payment mode of fiat currency
                is online.
                InStar reserves the right to manually adjust the Bitcoin to fiat currency exchange rate when there is an
                abnormal fluctuation in the Bitcoin market or other business risks.
                Upon completion of the order form and payment, the order may not be cancelled.

                <h3>Risks</h3>
                If mining farms may no longer continue operating due to change in legal status, war, earthquake, fire,
                electricity failure, or other acts of God, InStar does not assume responsibility for compensation.
                <h3>Operating</h3>
                When purchasing a miner on InStar, the amount of hashrate credited to the user will is a share in mining
                devices.
                InStar takes care of maintenance of devices such as: setting them up, ensuring internet connection,
                providing underlying services redundancy, in house diagnosis, using guarantee in case of malfunction,
                paperwork etc.
                All miners are pointed to pool of InStar choice. Income is being aggregated between all same type of
                contract users. In such a mode that if on out of 100 devices will fail for a day it will not affect
                single unlucky customer it will be distributed among all users of this type.

                <h3>Electricity and maintenance fees</h3>
                InStar uses a fixed electricity price and maintenance fee of 0.39 PLN per kWh. Electricity usage is
                calculated based on aggregated real infrastructure usage including supporting devices such as network
                devices etc…
                Electricity fees are calculated as follows: kWh per Th/s * 0.39 PLN.
                Current electricity usage (2017-04-05) per Th/s is 0.1349 kWh. We reserve right to update it.
                Electricity and maintenance fees are calculated once weekly.
                Fees are calculated using the current bitcoin exchange rate at time of payment to cover bills.
                Fees are deducted from the user’s income invisibly that users see pure profit.
                InStar reserves the right to make adjustments to the electricity and maintenance fees based on
                operational factors.
                Except in minor cases, InStar will announce any changes to these fees at least week in advance of them
                taking effect.

                <h3>Downtime</h3>
                InStar provides a 99% uptime.

                <h3>Communication</h3>
                In unlikely case of severe problem with instalation InStar will announce steps taken accordingly.

                <h3>Transaction Fees</h3>
                Bitcoin transaction fees will be included in maintenance fees.
                The buyer does not need to pay any extra fees.

                <h3>Operation Start</h3>
                We will start mining as soon as we will receive confirmation of your payment.
                In case of bitcoin payment it means we will wait for 6 blocks confirmation.
                You will not notice it instantly but it will be calculated within summary of week.

                <h3>Duration</h3>
                Contract is Valid up to Termination

                <h3>Profits Distribution Payment</h3>
                Payment is being done for every closed week within three working days.
                It will mostly happen within 24h but we reserve extra time for unexpected events.
                Only income fully confirmed by pool will be distributed for accounted week.

                <h3>Termination</h3>
                When operation will not be profitable anymore*.
                Contract will terminate and devices will be disposed by us free of charge.

                <h3>Definitions</h3>
                <em>Profitable - Operation is being consider as not profitable if weekly</em> income will not cover
                costs
                *<strong>Week</strong>s are being always counted as full calendar weeks UTC time

                <h3>User identification</h3>
                During payment you will have UserKeyID number which will be used in any need of contacting support.
                Important - If you lose generated UserKeyID, there is no way of recovering access,
                but regarles of that profits will be send to address given on order.
                <Divider />
            </div>
        )
    }
}