import React from 'react';
import {render} from 'react-dom';
//import registerServiceWorker from './registerServiceWorker';
import FullApp from './FullApp';
import 'semantic-ui-css/semantic.min.css';

render(<FullApp/>, document.getElementById('root'));
//registerServiceWorker();

/*let lang = 'pl';
const debug = false;
const translations = {
  pl: {
    localeData: plLocale,
    messages: {
      'buy sell bitcoin': "kup sprzedaj bitcoin",
        'BUY HASHRATE': 'ZAKUP MOCY OBLICZENIOWEJ',
        'Use the following hashrate order form to place your order': 'Skorzystaj z kalkulatora aby zamówić moc obliczeniową'
    }
  },
  en: {
    localeData: enLocale,
    messages: {
      'buy sell bitcoin': "buy sell bitcoin"
    }
  }
};



const Team = () => (
  <div>Team</div>
);

const Partners = () => (
  <div>Partners</div>
);

const Join = () => (
  <div>Join</div>
);


const Routes = () => (
  <Router history={browserHistory}>
    <Route path="/" component={App}>
      <IndexRoute component={Intro} />
      <Route path="/team" component={Team}/>
      <Route path="/partners" component={Partners}/>
      <Route path="/join" component={Join}/>
      <Route path="/cart" component={Cart}/>
      <Route path="/contract/:contractId" component={Contract}/>

    </Route>

  </Router>
);

const FullApp = () => (
  <Provider store={store}>
    <GlobeProvider
      lang={lang}
      translations={translations}
      debug={debug}>
      <Routes/>
    </GlobeProvider>
  </Provider>
);

render(<FullApp/>, document.getElementById('root'));*/