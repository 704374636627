import React, {Component} from 'react';
import {Input, Button, Grid, Divider, Message, Container} from 'semantic-ui-react'
import {t} from 'react-globe';
import axios from 'axios';
import qs from 'qs';
import debounce from 'debounce';
import Payment from './payment';
import ContractDetails from "./contract_details";
import Terms from "./terms";
import ComparisonTable from './comparison_table';

class Cart extends Component {

    constructor(props) {
        super(props);
        this.state = {
            btcAmount: 0,
            USD: 0,
            THS: 1,
            monthly: 0,
            weekly: 0,
            yearly: 0,
            amountType: 'THS',
            btcAddress: '',
            formErrors: [],
            errorList: []
        };

        this.getCalcs = debounce(this.getCalculations, 700);
        this.getCalcs();
        this.apiURL = "https://api.inpay.pl";

    }


    getCalculations() {
        let that = this;
        axios.post(this.apiURL + '/instar/calculate',
            qs.stringify({
                type: this.state.amountType,
                amount: this.state[this.state.amountType]
            })
        ).then(function (results) {

            that.setState({
                USD: results.data.usd,
                //btcAmount: (calcs.data.usd / calcs.data.btc_rates.USD * 1.02).toFixed(8),
                btcAmount: results.data.btc.valueWithFees,
                THS: results.data.ths,
                monthly: (results.data.monthly.btc).toFixed(5),
                weekly: (results.data.monthly.btc * 12 / 52).toFixed(5),
                yearly: (results.data.monthly.btc * 12).toFixed(5),

            });
        })
    }

    createOrder() {
        let that = this;
        axios.post(this.apiURL + '/instar/create',
            qs.stringify({
                type: this.state.amountType,
                amount: this.state[this.state.amountType],
                btcAddress: this.state.btcAddress
            })
        ).then(function (order) {
            if (order.data.success !== false) {

                that.setState({
                    errorList: null,
                    paymentAddress: order.data.btc_address,
                    code: order.data.code,
                    invoice_code: order.data.invoice_code,
                    prod_generation: order.data.prod_generation
                });
                that.getStatus();
            } else {
                console.log(order.data);
                let errorList = Object.keys(order.data.error).map(val => order.data.error[val]);
                that.setState({
                    formErrors: order.data.error,
                    errorList: errorList
                });
            }
        })
    }

    getStatus() {
        let that = this;
        if (this.state.code === undefined) {
            return null;
        }

        axios.post(this.apiURL + '/instar/status',
            qs.stringify({
                code: this.state.code
            })
        ).then(function (results) {
            console.log(results.data);
            let s = results.data;
            that.setState({
                contractDetails: {
                    code: s.code,
                    btc_address: s.btc_address,
                    prod_generation: s.prod_generation,
                    ths: s.ths,
                    close_date: s.payment.close_date
                },
                payment: s.payment
            })
        });

    }

    hashrateChange(event) {
        let newHashrate = event.target.value;
        if (newHashrate < 0.1) newHashrate = 0.1;
        if (newHashrate > 30) newHashrate = 30;
        this.setState({THS: newHashrate, amountType: 'THS'});
        this.getCalcs();
    }

    btcAddressChange(event) {
        this.setState({btcAddress: event.target.value});
    }

    USDAmountChange(event) {
        let newVal = event.target.value;
        if (newVal < 30) newVal = 30;
        if (newVal > 100000) newVal = 100000;
        this.setState({USD: newVal, amountType: 'USD'});
        this.getCalcs();
    }

    prepareOrder() {
        console.log(this.state);
        this.createOrder();
    }


    render() {

        return (
            <Container className="centered">
                <Divider/>
                <h1>{t('BUY HASHRATE')}</h1>
                <p>
                    {t('Use the following hashrate order form to place your order')}
                </p>

                <Grid columns={3} centered>
                    <Grid.Row columns={1}>
                        <Grid.Column>
                            <Divider horizontal>{t('MINING PRICE OR HASHRATE')}</Divider>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <Input
                                fluid
                                readOnly
                                value={"~" + this.state.btcAmount}
                                action={{labelPosition: 'left', icon: 'bitcoin', content: 'Price'}}
                                actionPosition='left'
                            />
                        </Grid.Column>
                        <Grid.Column>
                            <Input
                                onChange={this.USDAmountChange.bind(this)}
                                value={this.state.USD}
                                fluid
                                action={{labelPosition: 'left', icon: 'dollar', content: 'Price'}}
                                actionPosition='left'
                            />
                        </Grid.Column>
                        <Grid.Column>
                            <Input
                                onChange={this.hashrateChange.bind(this)}
                                value={this.state.THS}
                                fluid
                                action={{labelPosition: 'left', icon: 'dashboard', content: 'TH/s'}}
                                actionPosition='left'
                            />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={1}>
                        <Grid.Column>
                            <Divider horizontal>{t('PROFIT CALCULATOR')}</Divider>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <Input disabled
                                   fluid
                                   value={this.state.weekly}
                                   action={{labelPosition: 'left', icon: 'bitcoin', content: 'Weekly'}}
                                   actionPosition='left'
                                   placeholder=''
                            />
                        </Grid.Column>
                        <Grid.Column>
                            <Input disabled
                                   fluid
                                   value={this.state.monthly}
                                   action={{labelPosition: 'left', icon: 'bitcoin', content: 'Monthly'}}
                                   actionPosition='left'
                                   placeholder=''
                            />
                        </Grid.Column>
                        <Grid.Column>
                            <Input disabled
                                   fluid
                                   value={this.state.yearly}
                                   action={{labelPosition: 'left', icon: 'bitcoin', content: 'Yearly'}}
                                   actionPosition='left'
                            />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={1}>
                        <Grid.Column>
                            <p>
                                This is simulation based on current network hashrate. <a
                                href="https://bitcoinwisdom.com/bitcoin/difficulty">Hashrate history</a>.
                            </p>
                            <Divider horizontal>{t('MINING SETUP')}</Divider>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={2}>
                        <Grid.Column>
                            <Input
                                error={(this.state.formErrors['btcAddress'] !== undefined)}
                                fluid
                                onChange={this.btcAddressChange.bind(this)}
                                value={this.state.btcAddress}
                                action={{labelPosition: 'left', icon: 'bitcoin', content: 'Address'}}
                                actionPosition='left'
                                placeholder='Your Bitcoin Payout Address'
                            />
                        </Grid.Column>
                        <Grid.Column>
                            <Button
                                onClick={this.prepareOrder.bind(this)}
                                animated='fade' color="green" size='large' fluid>
                                <Button.Content visible>
                                    Start mining
                                </Button.Content>
                                <Button.Content hidden>
                                    Pay {this.state.btcAmount} BTC
                                </Button.Content>
                            </Button>
                        </Grid.Column>

                    </Grid.Row>

                    {this.state.errorList.length > 0 &&
                    <Grid.Row columns={1}>
                        <Grid.Column>
                            <Message
                                error
                                header={t('There were some errors')}
                                list={this.state.errorList}
                            />
                        </Grid.Column>
                    </Grid.Row>
                    }

                    <Grid.Row columns={1}>
                        <Grid.Column>
                            <Payment payment={this.state.payment}/>
                        </Grid.Column>
                    </Grid.Row>

                    <Grid.Row columns={1}>
                        <Grid.Column>
                            <ContractDetails contractData={this.state.contractDetails}/>
                        </Grid.Column>
                    </Grid.Row>

                    <Grid.Row columns={1}>
                        <Grid.Column>
                            <Divider horizontal>{t('Why to mine with InStar operator not on your own')}</Divider>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                <Divider/>
                <ComparisonTable/>
                <Terms/>
            </Container>
        );
    }
}

export default Cart