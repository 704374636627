import React, {Component} from 'react';
import {Message, Segment} from 'semantic-ui-react';
import {t} from 'react-globe';

export default class Error extends Component {
    render() {
        return (
            <Segment basic>
                <Message visible error header={t('Error')}/>
            </Segment>
        )
    }
}
