import React, {Component} from 'react';
import './App.css';
import * as actions from './actions';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {Route, Link, Switch} from 'react-router-dom';
import {Menu, Image, Sidebar, Icon, Segment, Container} from 'semantic-ui-react'
import logo from './images/instar_logo_gradient_white_text.svg';
import {t} from 'react-globe';

import Cart from './components/cart';
import Contract from './components/contract';
import Footer from './components/footer';
import NotFound from './components/notfound';
import Error from './components/error';
import Intro from './components/intro';
import Project from './components/project';
import Shareholders from "./components/shareholders";
import Team from "./components/team";
import Staff from "./components/staff";

function mapStateToProps(state) {
  return {
    stats: state.stats,
    user: state.user,
    bids: state.bids,
    asks: state.asks,
    arbitrage: state.arbitrage
  };
}

function mapDispatchToProps(dispatch) {
  return {actions: bindActionCreators(actions, dispatch)};
}

class App extends Component {
  state = {
    sideBarVisible: false,
  };

  toggleSidebarVisibility() {
    this.setState({sideBarVisible: !this.state.sideBarVisible});
  }

  hideSidebar() {
    if (this.state.sideBarVisible)
      this.setState({sideBarVisible: false});
  }

  menu() {
    return (
      <Menu id='main-menu' inverted secondary>
        <Menu.Item className="toc" style={{display: "none"}}>
          <Icon size="large" name='sidebar'
                onClick={this.toggleSidebarVisibility.bind(this)}/>
        </Menu.Item>
        <Menu.Item as={Link} to='/'>
          <Image src={logo} size="tiny"/>
        </Menu.Item>
        <Menu.Menu position='right'>
          <Menu.Item as={Link} to='/' className="toc" style={{display: "none"}}>
            <Image src={logo} size="tiny"/>
          </Menu.Item>
          <Menu.Item as={Link} to='/project' name='project'>{t('EU Project')}</Menu.Item>
          <Menu.Item as={Link} to='/shareholders' name='shareholders'>{t('Investor relations')}</Menu.Item>
          <Menu.Item as={Link} to='/team' name='team'>{t('The Team')}</Menu.Item>
        </Menu.Menu>
      </Menu>
    );
  }

  render() {
    return (
        <Sidebar.Pushable as={Segment}>
          <Sidebar as={Menu} animation='push' visible={this.state.sideBarVisible} vertical inverted>
            <Menu.Item as={Link} to='/' onClick={this.hideSidebar.bind(this)}>{t('Study')}</Menu.Item>
            <Menu.Item as={Link} to='/project' onClick={this.hideSidebar.bind(this)}>{t('EU Project')}</Menu.Item>
            <Menu.Item as={Link} to='/shareholders' onClick={this.hideSidebar.bind(this)}>{t('Investor relations')}</Menu.Item>
            <Menu.Item as={Link} to='/team' onClick={this.hideSidebar.bind(this)}>{t('The Team')}</Menu.Item>
          </Sidebar>

          <Sidebar.Pusher onClick={this.hideSidebar.bind(this)}>
            <Segment id='main-menu-container'>
              <Container>
                {this.menu()}
              </Container>
            </Segment>

              <Switch>
                <Route exact path="/" component={Intro}/>
                <Route path="/cart" component={Cart}/>
                <Route path="/card" component={Staff}/>
                <Route path="/project" component={Project}/>
                <Route path="/shareholders" component={Shareholders}/>
                <Route path="/team" component={Team}/>
                <Route path="/contract/:contractId" component={Contract}/>
                <Route path="/error" component={Error}/>
                <Route path="*" component={NotFound}/>
              </Switch>

            <Footer/>
          </Sidebar.Pusher>
        </Sidebar.Pushable>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
